<template>
	<section class="online-store">
		<div
			v-if="showUpgrade"
			class="online-store__upgrade"
		>
			<ZyroCard class="online-store__card">
				<h2 class="z-h4">
					{{ $t('siteSettings.onlineStore.titleUpgrade') }}
				</h2>

				<figure>
					<img
						class="online-store__image"
						src="@/assets/images/store-upgrade.png"
						:alt="$t('siteSettings.onlineStore.titleUpgrade')"
					>
				</figure>

				<div class="online-store__benefits">
					<ul class="online-store__benefits-list">
						<li
							v-for="benefit in $t('siteSettings.onlineStore.benefitsBlock1')"
							:key="benefit"
							class="online-store__benefit z-body-small"
						>
							<ZyroSvg
								name="check-mark"
								class="online-store__check"
							/>
							{{ benefit }}
						</li>
					</ul>
					<ul class="online-store__benefits-list">
						<li
							v-for="benefit in $t('siteSettings.onlineStore.benefitsBlock2')"
							:key="benefit"
							class="online-store__benefit z-body-small"
						>
							<ZyroSvg
								name="check-mark"
								class="online-store__check"
							/>
							{{ benefit }}
						</li>
					</ul>
				</div>

				<ZyroButton
					theme="primary"
					color="plump-purple"
					class="online-store__button"
					data-qa="sitesettings-onlinestore-btn-upgradewebsite"
					@click="handleUpgradeBtnClick"
				>
					{{ $t('siteSettings.onlineStore.buttonUpgrade') }}
				</ZyroButton>

				<div class="online-store__footnote">
					<p class="z-body-small">
						{{ $t('siteSettings.onlineStore.subtitleUpgrade') }}
						<b>{{ $t('siteSettings.onlineStore.guarantee') }}</b>
					</p>
				</div>
			</ZyroCard>
		</div>
		<template v-else>
			<ZyroSettingsTitle>
				{{ $t('siteSettings.nav.online-store') }}
			</ZyroSettingsTitle>
			<div class="online-store__manage">
				<ZyroText class="online-store__intro">
					<template #title>
						{{ $t('siteSettings.onlineStore.introTitle') }}
					</template>
					<template #text>
						{{ $t('siteSettings.onlineStore.introDescription') }}
					</template>
				</ZyroText>

				<ZyroCard class="online-store__card">
					<figure>
						<img
							class="online-store__image"
							src="@/assets/images/store-manage.png"
							:alt="$t('siteSettings.onlineStore.introTitle')"
						>
					</figure>
					<div v-if="isUserDesigner">
						<h1 class="online-store__manage-title z-h5">
							{{ $t('siteSettings.onlineStore.titleManageDesigner') }}
						</h1>
						<p class="z-body">
							{{ $t('siteSettings.onlineStore.textManageDesigner') }}
						</p>
					</div>
					<template v-else>
						<h2 class="z-h4">
							{{ $t('siteSettings.onlineStore.titleManage') }}
						</h2>
						<ZyroButton
							theme="primary"
							color="plump-purple"
							class="online-store__button"
							@click="handleManageBtnClick"
						>
							{{ $t('siteSettings.onlineStore.buttonManage') }}
						</ZyroButton>
					</template>
				</ZyroCard>
			</div>
		</template>
	</section>
</template>

<script>
import {
	mapActions,
	mapGetters,
	mapState,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';
import ZyroSettingsTitle from '@/components/site-settings/components/ZyroSettingsTitle.vue';
import ZyroText from '@/components/site-settings/components/ZyroText.vue';
import {
	STORE_MANAGER_ROUTE,
	SUBSCRIPTION_SETTINGS_ROUTE,
} from '@/router';

export default {
	components: {
		ZyroSettingsTitle,
		ZyroText,
		ZyroCard,
	},
	computed: {
		...mapGetters('subscription', ['hasEcommercePlan']),
		...mapState('user', ['isUserDesigner']),
		showUpgrade: ({
			hasEcommercePlan,
			isUserDesigner,
		}) => !hasEcommercePlan && !isUserDesigner,
	},
	methods: {
		...mapActions('subscription', ['autoConnectPlan']),
		async handleUpgradeBtnClick() {
			await this.autoConnectPlan({ ecommerceOnly: true });

			if (!this.showUpgrade) {
				this.handleManageBtnClick();

				return;
			}

			EventLogApi.logEvent({ eventName: 'site_settings.online_store.not_upgraded_click_button' });
			this.$router.push({ name: SUBSCRIPTION_SETTINGS_ROUTE });
		},
		handleManageBtnClick() {
			EventLogApi.logEvent({ eventName: 'site_settings.online_store.upgraded_click_button' });
			this.$router.push({ name: STORE_MANAGER_ROUTE });
		},
	},
};
</script>

<style lang="scss" scoped>
.online-store {
	&__manage {
		display: flex;

		@include mobile-view {
			flex-direction: column-reverse;
		}
	}

	&__upgrade {
		padding-top: 48px;
	}

	&__intro {
		flex-basis: 53%;
		padding-right: 11%;

		@include mobile-view {
			flex-basis: auto;
			padding-top: 32px;
		}
	}

	&__card {
		padding-top: 40px;
		text-align: center;
	}

	&__image {
		display: inline-block;
		max-width: 100%;
		margin: 16px auto;

		@include mobile-view {
			margin: 16px -16px;
		}
	}

	&__button {
		margin: 36px auto;

		@include mobile-view {
			margin: 24px auto;
		}
	}

	&__benefits {
		display: flex;
		flex-wrap: wrap;
		padding: 16px 20px 0;
		text-align: left;

		@include mobile-view {
			display: block;
			padding: 0;
		}
	}

	&__benefits-list {
		width: 50%;
		padding-left: 48px;

		@include mobile-view {
			width: 100%;
			padding-left: 0;
		}
	}

	&__benefit {
		padding: 6px 0;
		list-style-type: none;
	}

	&__check {
		display: inline-block;
		width: 16px;
		height: 11px;
		margin-right: 6px;
		color: var(--accent-two);
	}

	&__footnote {
		padding: 27px 40px 3px;
		margin: 0 -40px;
		border-top: solid 1px var(--grey-200);
	}
}
</style>
